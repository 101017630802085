

.containerLogin {
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: rgb(6, 6, 6);
  position: absolute;
  height: 100vh;
}

.loginButton {
  width: 100%;
  height: 50px;
  background-color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
  color: rgb(6, 6, 6);
}


.login{
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*background-color: rgb(6, 6, 6);*/
  margin-bottom: 120px;
}

.popupToken{
  width: 102.5%;
  display: flex;
}
.dcmLogo {
  width: 400px;
  height: auto;
  background-size: contain;
  background-position: center;
  margin: 50px 0 40px 0;
  margin-left: 0;
}

.inputUser {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
}
.userInput{
  width: 100%;
  height: 100%;
  padding: 13px 0;
  margin: 6px 0;
  border-radius: 8px;
  padding-left: 5px;
  text-indent: 20px;
  outline: none;
}
.userInput::placeholder {
  text-indent: 20px; 
  color: #dadada;
}

.loginButton{
  width: 410px;
  height: 100%;
  padding: 13px 0;
  margin: 4px 0;
  text-align:  center;
  border-radius: 8px;
  outline: none;
}

.popupToken{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 100%;
}

.inactive {
  background-color: #e7e7e7; 
  color: rgb(170, 170, 170);
}


/* Media Query para telas menores*/
@media (max-width: 992px) {

  .dcmLogo {
    width: 350px;
    margin: 50px 0 40px 0;
    margin-left: 0;
  }
  .inputUser {
    width: 350px;
  }
  .popupToken{
    width: 350px;
  }
 .loginButton{
    width: 360px;
 }
}
/* Media Query para, dispositivos móveis */
@media (max-width: 576px) {

  .dcmLogo {
    width: 250px;
    margin: 50px 0 40px 0;
    margin-left: 0;
  }
  .inputUser {
    width: 250px;
  }
  .popupToken{
    width: 250px;
  }
 .loginButton{
    width: 250px;
 }
}