.MonitoringPage{
    margin: 0;
    padding: 0;
    font-family: 'HelveticaNeueLTPro-lt', sans-serif;
}
.mpContainer{
    display:flex;
    flex-direction: column;
    height: 100vh;
}
.mpHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: #000000;
    height: auto;
    color: #fff;
    font-size: 20px;
}

.firLogoW{
    width: 200px;
}
.mpTable{
    display: flex;
    flex-direction: column;
    margin: 20px 10px;
    border-radius: 10px 10px 0 0;
    flex: 1;
    
}

.mpTableleft{
    border-radius: 10px 10px 0 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.39);
}

.mpTableContent{
    display: flex;
    margin: auto;
    margin-top: 20px;
}

.rowHeaderContent{
    display: flex;
    align-items: center;
    color: white;
}

.title{
    font-size: 25px;
    font-weight: 700;
    margin: 0;
    min-width: 300px;
    background-color: #818285;
    padding: 5px 10px;
}

.tStatus{
    font-size: 25px;
    font-weight: 700;
    margin: 0;
    min-width: 100px;
    background-color: #818285;
    padding: 5px 10px;
}
.rowContent{
    display: flex;
    align-items: center;
    border: solid 1px #818285;
    
}

.content{
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    min-width: 300px;
    padding: 0px 10px;
   
}

.status{
    width: 40px;
    padding: 5px;
    margin: 0 20px;
    border-radius: 10px;
    text-align: center;
    padding: 0px 10px;
}

.mpFooter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: #fafafa;
    height: auto;
    color: #fff;
    font-size: 20px;
}

.rBorderLeft{
    border-radius: 10px 0 0 0;
}
.rBorderRight{
    border-radius: 0 10px 0 0 ;
}