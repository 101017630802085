
body {
  font-family: 'HelveticaNeueLTPro-lt', sans-serif;
  margin: 0;
}
header {
    color: #fff;
    text-align: center;
    display: flex;   
    box-shadow: 0 20px 40px rgba(0,0,0,.1);
    justify-content: space-between;
    width: 100vw;
    height: 130px;
    position: fixed;
    top: 0;
    z-index: 100;
  }
  .clientLogo{
    object-fit: contain;
    margin: 0 80px;
    width: 200px;
    height: auto;
  }
  .userOffice{
    display: flex;
    flex-direction: row;
  }
  .userIcon{
    margin: auto;
    max-width: 100px;
    max-height: 100px;
    object-fit: contain;
  }
  .textUserOffice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    text-align: left;
    width: 100%;
    padding-left: 40px;
  }
  
  .textUserOffice h1, .textUserOffice p {
    margin: 0;
    width: 100%;
  }
  .inputSearch{
    align-items: center;
    justify-content: center;
    display: flex;
    width: 23%;
  }

  .searchBar::placeholder {
    color: #dadada;
    text-indent: 30px; /* Ajuste o valor conforme necessário */
  }

  .logoutIcon{
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 36px;
    margin-right: 90px;
    max-height: auto;
    object-fit: contain; /* ajusta de no centro de acordo com o tamanho da img */
    cursor: pointer;
  }
  .imgLogoutPassword{
    align-items: center;
    justify-content: center;
    display: flex;
  }
  
  .logoUsercontainer {
    margin: 0;
    display: flex;
    flex-direction: row;
  }

  /*menu*/
  .dropdown {
    position: relative;
  }
  
  .menuRender {
    position: absolute;
    top: 90px;
    right:34px;
    color: rgb(230, 230, 230);
    background-color: rgb(63, 63, 63);
    border-radius: 4px;
    padding: 10px;
    padding-top: 20px;
    width: max-content;
    height: auto;
 
    clip-path: polygon(0% 12%,2% 10%, 0% 10%, 45% 10%, 50% 0%, 50% 0%, 55% 10%, 100% 10%, 98% 10%,100% 12%, 100% 100%, 0% 100%);
  }
  
  .menu-open {
    display: block;
  }
  
  .menu-closed {
    display: none;
  }

  .menuRender ul {
    list-style: none;
    padding: 0;
  }
  
  .menuRender li {
    display: flex;
    align-items: center;
    padding: 8px;
  }
  .menuRender p{
    cursor: pointer;
    font-size: 12px;
    margin: 0;
  }
  
  .menuImg {
    cursor: pointer;
    margin-right: 8px;
    max-width: 15px;
    max-height: auto;
  }


  /* Media Query para telas menores*/


  @media (max-width: 992px) {
    
    .clientLogo{
      max-width: 150px;
      margin: 10px 20px;
    }
    .logoutIcon{

      margin-right: 20px;
    }
    .textUserOffice{
      padding: 0;
      margin-left: 10px;
    }

    .menuRender {
      position: absolute;
      top: 90px;
      right:15px;
      color: rgb(230, 230, 230);
      background-color: rgb(63, 63, 63);
      border-radius: 4px;
      padding: 10px;
      padding-top: 20px;
      width: max-content;
      height: auto;
   
      clip-path: polygon(0% 12%,2% 10%, 0% 10%, 80% 10%, 85% 0%, 85% 0%, 90% 10%, 100% 10%, 98% 10%,100% 12%, 100% 100%, 0% 100%);
    }

  }

  /* Media Query para telas dispositivos móveis*/
  @media (max-width: 576px) {
  
      .clientLogo{
        width: 100px;
        margin: 10px 15px;
      }
      .logoutIcon{
        max-width: 30px;
        margin-right: 20px;
        margin-left: 10px;
      }
      .userIcon{
        display: none;
      }
      .textUserOffice {
        padding: 0;
        font-size: 10px;
      }
    
    }