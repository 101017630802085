footer {
    background-color: #ffffff;
    color: #fff;
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 120px;
    display: flex;
  }
.footerContent{
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.plmLogoBlack {
  width: 200px;
  height: 110px; 
  object-fit: contain; /* ajusta de no centro de acordo com o tamanho da img */
  object-position: center;
  display: block;
  margin: 10px 70px 0 0;
}
.sloganImg {
  width: 200px;
  height: 110px; 
  object-fit: contain; /* ajusta de no centro de acordo com o tamanho da img */
  object-position: center;
  display: block;
  margin: 10px 70px;
}
/* Media Query para telas menores*/
@media (max-width: 992px) {

  .plmLogoBlack, .sloganImg {
    max-width: 150px !important;  
    height: auto !important;      
    margin: 0;                    
  }

  .footerContent {
    
    height: 80px;
    margin:auto;
    flex-direction: row;  
    align-items: center;  
    padding: 10px;   
   
  }

}
/* Media Query para dispositivos móveis */

@media (max-width: 576px) {
  .plmLogoBlack, .sloganImg {
    max-width: 150px !important;  
    height: auto !important;      
    margin: 0;                    
  }

  .footerContent {
    width: 100vw;
    height: 120px;
    margin:auto;
    flex-direction: row;  
    align-items: center;  
    padding: 10px;   
    
  }
}