body {
  font-family: 'HelveticaNeueLTPro-lt', sans-serif;
  margin: 0;
}

.dashboardWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.containerDashboard {
  background-color: rgb(244, 244, 244);
  flex: 1; 
  display: grid;
  gap: 50px;
  padding: 50px 70px;
  padding-bottom:9% ;
  grid-template-columns: auto auto;
}
.generalContainerDashboard{
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  border: solid rgba(145, 145, 145, 0.425) 1px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.39);
  max-height: 600px; /* Definir uma altura máxima */
}
.generalBar{
  margin: 0;
  padding: 10px 0 10px 20px;
  border-radius: 10px 10px 0 0 ;
  display: flex;
  justify-content: space-between;
}
.txtGeneralBar{
  margin: 0;
  color: white;
  display: flex;
  align-items: center;
}
p.txtGeneralBar{
  margin:8px 0 8px 0 ;
}
.lojaHistoryContainer{
  display: flex;
  flex-direction: column;
  gap: 65px;
  max-height: 580px; 
}


.lojaContainer,
.historyContainer { 
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  border: solid rgba(145, 145, 145, 0.425) 1px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.39);
  min-height: 258px;
  
}


.infoGeneralContainer{
  padding: 20px;
  height: 500px;
  overflow-y: auto; /* Adicionando overflow-y aos containers de loja e histórico */
  box-sizing: border-box;
}
.infoGeneralContainerL{
  padding: 10px;
  height: 170px;
  overflow-y: auto; /* Adicionando overflow-y aos containers de loja e histórico */
  box-sizing: border-box;
}


.uploadIcon{
  width: 30px;
  margin-right: 35px;
}
.backIcon{
  rotate: 180deg;
  width: 30px;
  margin-right: 35px;
}


.searchFle, .trashIcon, .texteIcon{
width: 30px;
margin-right: 35px;
}



.playIcon{
  background-color: #818285 ;
  width: 25px;
  height: 25px;
  border-radius: 3px;
}
.video-container {
  border: 2px solid #000000;
}

.close-button {
  position: fixed;
  top: 50px; /* Ajuste conforme necessário para alinhar com o vídeo */
  left: 50px;
  font-size: large;
  font-weight: bold;
  background-color: #fff; /* Cor de fundo do botão */
  border: 1px solid #ccc; /* Borda do botão */
  padding: 5px 10px; /* Espaçamento interno do botão */
  cursor: pointer;
}

.folderN{
  width: 30px;
  height: auto;
  margin:0 5px;
  object-fit: contain; /* ajusta de no centro de acordo com o tamanho da img */
}

ul {
  list-style-type: none; /* Remove os marcadores padrão */
  padding: 0; 
  margin: 0;
}


.changePasswordContainer{
  width: 600px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#060706 ;
  color: white;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.39);
  border-radius: 10px;
}

.changePasswordContainer input{
outline: none;
border: none;
width: 400px;

}
.changePassword{
  width: 100%; /* Largura total da tela */
  height: 100%; /* Altura total da tela */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #22222286;
  padding: 20px;
  z-index: 999;
  border-radius: 5px;
}
.userInput{
  width: 100%;
  height: 100%;
  padding: 13px 0;
  margin: 2px;
  border-radius: 8px;
  padding-left: 5px;
  text-indent: 20px;
  outline: none;
  border: none;
}

.passwordButton{
  width: 100%;
  height: 100%;
  padding: 13px 0;
  margin: 5px 0;
  border-radius: 8px;
  padding-left: 5px;
  text-indent: 20px;
  outline: none;
  border: none;
  font-size: 16px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.39);
}

.generalScreenContainer {
  width: 100%;
}
.generalScreenBox{
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.iconsGeneral{
  display: flex;
  align-items: center;
}
.orientacaoTela{
  color: #b0b0b0;
}
/*loadscreen*/

.loadingRender {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(143, 143, 143, 0.377); /* Fundo semitransparente */
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centraliza verticalmente */
  align-items: center; /* Centraliza horizontalmente */
  z-index: 1000; /* Garante que fique sobre todos os outros elementos */
}

.loader {
  border: 12px solid #f3f3f3; /* Cor clara */
  border-top: 12px solid #777777; /* Cor principal */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

.loadingRender p {
  font-size: 18px;
  margin-top: 10px;
}

/*-----------------------------------------calendar*/



.renderCalendar {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 350px;
  height: 420px;
  margin-top: -210px;
  margin-left: -175px;
  background-color: #E5E5E5; /* Fundo semitransparente */
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  z-index: 1000; /* Garante que fique sobre todos os outros elementos */
  border-radius: 15px;
  border: 1px solid white;
}

.programContent{
height: 380px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin:auto;
}


.calendar {
  margin: auto;
  width: 290px;
  height: 310px;
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 0;
  background-color: white;
  border-radius: 10px;
}

.calendar-open {
  display: flex; 
}

.calendar-closed {
  display: none;
}


.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  background-color: #818285;
  color:white;
  border-radius: 10px 10px 0 0;
  padding: 0 10px;
}
.btnCalendar{
  max-width: 20px;
}
.prev {
  rotate: 180deg;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  padding: 10px;
  min-height: 200px;
}

.day-name {
  font-weight: bold;
}

.day {
  padding: 3px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  border-radius: 4px;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 15px;
  margin: auto;
  cursor: pointer;
}

.past-day {
  background-color: #f0f0f0; 
  color: #a0a0a0;         
  pointer-events: none;   
}

.day.empty {
  background-color: transparent;
  border: none;
}

.selected-day {
  background-color: #818285;
  color: white;
}
.btnCloseCalendar {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
  font-size: 20px;
  max-width: 20px;
}
.btnConfirmDate {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
  font-size: 20px;
  max-width: 20px;
}


/* Relogio */
.clockContent{
  background-color: white;
  width: 290px;
  
  border-radius: 10px;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
}
.time-input {
  height: 50px;
  display: flex;
  justify-content: center;
  margin:auto;
  gap: 10px;
}

.time-input label {
  font-size: 14px;
  gap:10px;
  margin: auto;
}

.time-input input {
  width: 50px;
  padding: 5px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  border-radius: 4px;
  margin-left: 10px;
}


/* -------------------------------------Animação de rotação */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-open {
  display: flex; 
}

.loading-closed {
  display: none;
}
/*tela de confirmção de cancelamento*/


.confirmDeletContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  color: #333333; 
  margin: auto;
  max-width: 500px;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
  border: 1px solid #e0e0e0; 
}

.confirmDeletContent p {
  font-size: 1rem;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1.5;
}
.confirmDeletBtns{
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.confirmDeletBtns button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  background-color: #818285;
  color: white;

}


.confirmDeletContent button:hover {
  transform: scale(1.05); /* Animação leve ao passar o mouse */
}



@media (max-width: 1280px) {
.generalScreenBox{
  display: flex;
  flex-direction: column;
}
}

@media (max-width: 992px) {
  .containerDashboard {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 15px 180px 15px !important;
    
  }
  .generalContainerDashboard, .lojaHistoryContainer {
    width: 100%;
  }
  .txtGeneralBar p{
    margin-right: 20px;
  }

  .confirmDeletContent {
    width: 80%;
    max-width: 500px;
    padding: 10px;
  }
  .loadingRender p{
    font-size: 14px ;
  }
  .iconsGeneral{
    display: flex;
    flex-direction: row;
  }

}
@media (max-width: 576px) {

  .generalScreenBox > div {
    width: 100%;
  }
  .iconsGeneral{
    gap: 0 !important;
  }
  .searchFle, .trashIcon, .texteIcon, .backIcon, .uploadIcon{
    width: 25px !important;
    margin-right: 15px !important;
    }
  .txtGeneralBar p{
    margin-right: 20px;
  }
  .generalBar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .generalScreenBox{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}