body {
  font-family: 'HelveticaNeueLTPro-lt', sans-serif;
  margin: 0;
  padding: 0;
}

.admPageWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex: 1;
}

.containerDashboard {
  background-color: rgb(244, 244, 244);
  margin-top: 130px;
  display: grid;
  gap: 50px;
  padding: 50px 70px;
  padding-bottom:9% ;
  grid-template-columns: auto auto;
}
.generalContainerDashboard{
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  border: solid rgba(145, 145, 145, 0.425) 1px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.39);
  max-height: 600px; /* Definir uma altura máxima */
}
.generalBar{
  margin: 0;
  padding: 10px 0 10px 20px;
  border-radius: 10px 10px 0 0 ;
  display: flex;
  justify-content: space-between;
}
.txtGeneralBar{
  margin: 0;
  color: white;
  display: flex;
  align-items: center;
}
p.txtGeneralBar{
  margin:8px 0 8px 0 ;
}
.lojaHistoryContainer{
  display: flex;
  flex-direction: column;
  gap: 65px;
  max-height: 580px; 
}


.lojaContainer,
.historyContainer { /* Corrigindo a classe do container de histórico */
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  border: solid rgba(145, 145, 145, 0.425) 1px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.39);
  min-height: 258px;
  
}


.infoGeneralContainer{
  padding: 20px;
  height: 500px;
  overflow-y: auto; /* Adicionando overflow-y aos containers de loja e histórico */
}
.infoGeneralContainerL{
  padding: 10px;
  height: 170px;
  overflow-y: auto; /* Adicionando overflow-y aos containers de loja e histórico */
}


.uploadIcon{
  width: 30px;
  margin-right: 35px;
}
.backIcon{
  rotate: 180deg;
  width: 30px;
  margin-right: 35px;

}


.searchFle, .trashIcon{
width: 30px;
margin-right: 35px;
}





.playIcon{
  background-color: #818285 ;
  width: 25px;
  height: 25px;
  border-radius: 3px;
}
.video-container {
  border: 2px solid #000000;
}

.close-button {
  position: fixed;
  top: 50px; /* Ajuste conforme necessário para alinhar com o vídeo */
  left: 50px;
  font-size: large;
  font-weight: bold;
  background-color: #fff; /* Cor de fundo do botão */
  border: 1px solid #ccc; /* Borda do botão */
  padding: 5px 10px; /* Espaçamento interno do botão */
  cursor: pointer;
}

.folderN{
  width: 30px;
  height: auto;
  margin:0 5px;
  object-fit: contain; /* ajusta de no centro de acordo com o tamanho da img */
}

ul {
  list-style-type: none; /* Remove os marcadores padrão */
  padding: 0; 
  margin: 0;
}


.changePasswordContainer{
  width: 600px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#060706 ;
  color: white;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.39);
  border-radius: 10px;
}

.changePasswordContainer input{
outline: none;
border: none;
width: 400px;

}
.changePassword{
  width: 100%; /* Largura total da tela */
  height: 100%; /* Altura total da tela */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #22222286;
  padding: 20px;
  z-index: 999;
  border-radius: 5px;
}
.userInput{
  width: 100%;
  height: 100%;
  padding: 13px 0;
  margin: 2px;
  border-radius: 8px;
  padding-left: 5px;
  text-indent: 20px;
  outline: none;
  border: none;
}

.passwordButton{
  width: 100%;
  height: 100%;
  padding: 13px 0;
  margin: 5px 0;
  border-radius: 8px;
  padding-left: 5px;
  text-indent: 20px;
  outline: none;
  border: none;
  font-size: 16px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.39);
}

.generalScreenContainer {
  width: 100%;
}
.generalScreenBox{
  padding-top: 20px;
  width: 100%;
  display: flex;
  gap: 30%;

}
.iconsGeneral{
  display: flex;
  gap: 20px;
  align-items: center;
}
.orientacaoTela{
  color: #b0b0b0;
}


.dcmLogoAdmin{
padding: 20px;
margin: 0 50px;
}

@media (max-width: 992px) {
/*header*/
.dcmLogoAdmin{
  max-width: 100px;
  padding: 20px;
  margin: auto;
  }

}